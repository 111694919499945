.FaqPage {
  width: 100%;
  height: 100vh;
}

.FaqPage-container {
  padding: 40px 20px;
}

.FaqPage-container .faq-form-input {
  width: 100%;
  height: 50px;
}

.FaqPage-container .faq-from-btn {
  width: 100%;
  height: 50px;
  border: unset !important;
  background: #1b1c1e;
  font-weight: 700;
}
.faq-from-btn[disabled] {
  background-color: rgb(0 0 0 / 17%) !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
