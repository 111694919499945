.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.form .ant-btn {
  height: 50px !important;
  background: #1b1c1e !important;
  margin-top: 16px;
}

.form h3 {
  margin: 0 0 12px 0;
}

.form .ant-form-item-label {
  padding: 0 !important;
}

.form .react-tel-input .form-control {
  width: 100% !important;
  height: 44px !important;
}

.input {
  width: 100%;
  padding: 010px;
  outline: none !important;
}

.select {
  padding: 10px;
  height: 55px;
  margin-top: 15px;
  border-radius: 8px;
  border: 2px solid #6a22f3;
}

.registration-btn {
  font-weight: 700;
}

.registration-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.auth-space {
  padding-top: 400px;
  height: 260px;
  width: 100%;
}