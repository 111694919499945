.CurrenciesPage {
  width: 100%;
  height: 100vh;
}

.CurrenciesPage-container {
  padding: 40px 20px;
}

.CurrenciesPage-container .currency-form-input {
  width: 100%;
  height: 50px;
}

.CurrenciesPage-container .currency-from-btn {
  width: 100%;
  height: 50px;
  border: unset !important;
  background: #1b1c1e;
  font-weight: 700;
}

/* .currency-from-btn[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
  color: #d9d9d9 ;
  cursor: not-allowed;
} */

.excnahgeCurrency {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.excnahgeCurrency svg {
  width: 32px;
  height: 32px;
}
.exch-hr {
  width: 100%;
  height: 2px;
  background: #000;
  margin: 24px 0;
}

.tj-cur {
  font-weight: bold;
  color: #000;
}
