.ReceiptPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ReceiptPage-wrapper {
  min-width: 375px;
}

.ReceiptPage-wrapper .file_upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ReceiptPage-title {
  padding: 16px 0;
  text-align: center;
}

.ReceiptPage-action {
  padding: 24px 0;
}

.ReceiptPage-action .receipt-order {
  background: #1b1c1e;
}

.ReceiptPage-action .receipt-order:hover {
  background: #1b1c1e;
}

.ReceiptPage-action .receipt-order[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
