.ShowOrdersPage {
  width: 100%;
  padding: 32px 20px;
}

.ShowOrdersPage-cards h4 {
  padding: 16px 0;
  font-weight: 500;
}
.ShowOrdersPage-cards h4 span {
  font-weight: bold;
}

.ShowOrdersPage-card {
  width: 100%;
  height: auto;
  min-height: 100px;
  border-radius: 8px;
  border: 1px solid #b6b6b6;
  box-shadow: 0 2px 22px #0000003a;
  border-left: unset;
  position: relative;
  padding: 12px;
  overflow: hidden;
  margin-bottom: 24px;
}

.ShowOrdersPage-card-border-left {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  min-height: 100px;
  background: #1b1c1e;
}

.ShowOrdersPage-card-content {
  margin: 0;
  padding: 0;
}

.ShowOrdersPage-card-content li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

/* status */

.ShowOrdersPage-card-content .status-new {
  width: 100px !important;
  padding: 6px;
  border-radius: 6px;
  background: #ee3f3f !important;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShowOrdersPage-card-content .status-inProgress {
  width: 100px !important;
  padding: 6px;
  border-radius: 6px;
  background: #eed03f !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShowOrdersPage-card-content .status-done {
  width: 100px !important;
  padding: 6px;
  border-radius: 6px;
  background: #ad9000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShowOrdersPage-card-content .status-canceled {
  width: 100px !important;
  padding: 6px;
  border-radius: 6px;
  background: #c6c6c6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShowOrdersPage-card-content .status-checked {
  width: 100px !important;
  padding: 6px;
  border-radius: 6px;
  background: #4b9f7b !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ShowOrdersPage-card-content li span:first-child {
  font-size: 16px;
  font-weight: normal;
}

.ShowOrdersPage-card-content li span:last-child {
  font-size: 14px;
  text-align: right;
}

.ShowOrdersPage-card-content li .elipsis {
  text-align: right;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ShowOrdersPage-card-content .qrcode {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.ShowOrdersPage-card-content .qrcode img {
  width: 100%;
  height: 220px;
  border-radius: 12px;
  object-fit: cover;
}
