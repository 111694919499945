.ExchangePage {
  padding: 16px;
  min-height: 1100px;
}

.ExchangePage .react-tel-input .form-control {
  width: 100% !important;
}

.ExchangePage .ant-upload {
  width: 140px !important;
}

.ExchangePage .preview_img img {
  width: 110px;
  height: 110px;
  border-radius: 12px;
}

.ExchangePage-action {
  padding: 24px 0;
}

.ExchangePage-action button {
  background: #1b1c1e !important;
}

.ExchangePage-action button:disabled,
button[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ExchangePage-cuurency {
    display: flex;
    gap: 16px;
    flex-direction: column;
}
.form-error {
  font-size: 12px;
  font-weight: 500;
  color: #f30707;
}