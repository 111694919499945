.InstallPasswordPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
}
.ireceipt-order {
    margin-top: 24px;
    background: #1b1c1e;
  }
  
.ireceipt-order:hover {
  background: #1b1c1e !important;
}
