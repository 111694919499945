.error {
  font-size: 16px;
  color: red;
  padding: 12px 8px;
}

.detail-form-container {
  max-width: 1000px;
  position: relative;
  margin: 0 auto;
  padding: 24px 8px 16px 8px;
  margin-bottom: 250px;
}
.order-title {
  margin-bottom: 10px;
}
.detail-form-container .ant-btn {
  height: 50px !important;
  background: #1b1c1e !important;
}
.create-order {
  font-weight: 700;
}
.create-order[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.active {
  display: block !important;
}

.detail-form {
  display: none;
}

.fade {
  transition: 0.5s opacity ease-in-out;
  animation-name: fade;
  animation-duration: 1.5s;
}

.detail-form-footer {
  margin: 20px 0 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-form-input {
  width: 100%;
  padding: 10px;
  outline: none !important;
}

.detail-form input:focus {
  border-color: unset !important;
}

.detail-form textarea:focus {
  border-color: #000 !important;
}

.css-dev-only-do-not-override-k7429z {
  border-color: #000 !important;
}

.detail-form .ant-form-item-label {
  padding: 0 !important;
}

.actions {
  display: flex;
  gap: 12px;
}

.action-add {
  font-size: 12px;
}

.detail-form-footer button {
  padding: 16px 8px;
  border: none;
  outline: none;
  background: #1b1c1e;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}

.detail-form-footer button[disabled] {
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.detail-form-footer .remove-btn {
  background: rgb(237, 12, 12);
  color: #fff;
}

.detail-form-container input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px dashed #696969c6;
  border-radius: 4px;
  display: inline-block;
  padding: 8px;
  width: calc(100% - 16px);
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  cursor: pointer;
}

.file_upload {
  display: flex;
  align-items: top;
  height: 100px;
  gap: 24px;
}

.file_upload .ant-upload-select {
  width: 130px !important;
}

.file_upload img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  object-fit: cover;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
